<template>
  <div id="main">
    <DFSHeader />
    <SpeciesDetailContent />
    <DFSFooter />
  </div>
</template>

<script>
import DFSHeader from '@/components/DFSHeader';
import DFSFooter from '@/components/DFSFooter';
import SpeciesDetailContent from './components/SpeciesDetailContent.vue';

export default{
  name: 'TaxDetail',
  components: {
    DFSHeader,
    DFSFooter,
    SpeciesDetailContent
  }
}
</script>
