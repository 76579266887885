<template>
  <div id="main-content">
    <a-modal
      title="序列"
      :width="600"
      :visible="seqVisible"
      @ok="() => (seqVisible = false)"
      @cancel="() => (seqVisible = false)"
    >
      <div class="fasta">{{ fasta }}</div>
        <template slot="footer">
          <a-button @click="saveSeq">下载</a-button>
          <a-button type="primary" @click="() => (seqVisible = false)">确定</a-button>
        </template>
    </a-modal>
    <a-modal
      :title="mapTitle"
      :width="800"
      :visible="mapVisible"
      :bodyStyle="{padding: 0}"
      :footer="null"
      @ok="() => (mapVisible = false)"
      @cancel="() => (mapVisible = false)"
    >
      <div id="map"></div>
    </a-modal>
    <a-card title="物种详情" v-if="species">
      <a-spin :spinning="loading">
        <a-row class="tax-detail-header">
          <a-col class="tax-pic" :span="10">
            <swiper class="swiper" ref="picSwiper" :options="swiperOptions">
              <swiper-slide v-for="pic in pics" :key="pic.slug">
                <div class="thumbnail-pic">
                  <a @click="onPicClick(pic)">
                    <img :src="pic.thumbnail">
                  </a>
                </div>
              </swiper-slide>
              <div class="swiper-pagination" slot="pagination"></div>
              <div class="swiper-button-prev" slot="button-prev"></div>
              <div class="swiper-button-next" slot="button-next"></div>
            </swiper>
          </a-col>
          <a-col class="tax-summary" :span="14">
            <a-descriptions
              size="small"
              :column="1"
              bordered
            >
              <a-descriptions-item label="拉丁名">
                <span v-html="species.sciname"></span>&nbsp;&nbsp;
                <a :href="model" target="_blank" v-if="model">查看3D模型</a>
              </a-descriptions-item>
              <a-descriptions-item label="中文名">
                {{species.name}}
              </a-descriptions-item>
              <a-descriptions-item label="编号">
                {{species.slug}}
              </a-descriptions-item>
              <a-descriptions-item label="异名">
                <a-list size="small" :split="false">
                  <a-list-item
                    v-for="item in species.synonym"
                    :key="item"
                  >
                    <span v-html="item"></span>
                  </a-list-item>
                </a-list>
              </a-descriptions-item>
              <a-descriptions-item label="分类系统">
                <a-descriptions size="small" bordered>
                  <a-descriptions-item
                    v-for="taxon in species.lineage"
                    :key="taxon.slug"
                    :label="taxon.rank"
                  >
                    {{ taxon.name }}
                  </a-descriptions-item>
                </a-descriptions>
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
        </a-row>
        <div class="tax-detail-body">
          <a-descriptions :column="1" layout="vertical">
            <a-descriptions-item label="序列">
              <a v-for="(seqSlug, index) in species.seqs" :key="seqSlug" @click="onSeqClick(seqSlug)">
                <span v-if="index < (species.seqs.length - 1)">{{ seqSlug }}&nbsp;&nbsp;</span>
                <span v-else>{{seqSlug}}</span>
              </a>
            </a-descriptions-item>
            <a-descriptions-item label="形态描述">
              {{species.morphology}}
            </a-descriptions-item>
            <a-descriptions-item label="参考文献">
              <a-list size="small" :split="false">
                <a-list-item
                  v-for="reference in species.references"
                  :key="reference"
                >
                  {{ reference }}
                </a-list-item>
              </a-list>
            </a-descriptions-item>
            <a-descriptions-item label="分布地">
              <a @click="showDistributionMap">（查看地图）</a>
              <span
                v-for="(distributionItem, index) in species.distribution"
                :key="index"
              >
                {{ getAddrName(distributionItem) }};
              </span>
            </a-descriptions-item>
            <a-descriptions-item label="采集地">
              <a @click="showCollectionMap">（查看地图）</a>
              <span
                v-for="(collectionItem, index) in species.collection"
                :key="index"
              >
                {{ getAddrName(collectionItem) }}
                &nbsp;&nbsp;
              </span>
            </a-descriptions-item>
          </a-descriptions>
        </div>
      </a-spin>
    </a-card>
  </div>
</template>

<script>
import 'swiper/dist/css/swiper.css';
import {swiper, swiperSlide} from 'vue-awesome-swiper';
import {saveAs} from 'file-saver';

import {getSeqDetail} from '@/services/seq';
import {getSpeciesDetail} from '@/services/species';


export default {
  name: 'SpeciesDetailContent',
  components: {
    swiper, swiperSlide
  },
  data() {
    return {
      loading: false,
      picVisible: false,
      seqVisible: false,
      species: null,
      pics: null,
      lossless: '',
      seqSlug: '',
      fasta: '',
      swiperOptions: {
        loop: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      mapTitle: '',
      mapVisible: false,
      mapSpinning: false,
      map: null,
      models: {
        'VSL-S00000001': '',
        'VSL-S00000002': 'https://vector3d.hsientang.com/index.html?id=6',
        'VSL-S00000003': 'https://vector3d.hsientang.com/index.html?id=3',
        'VSL-S00000004': '',
        'VSL-S00000005': '',
        'VSL-S00000006': 'https://vector3d.hsientang.com/index.html?id=1',
        'VSL-S00000007': 'https://vector3d.hsientang.com/index.html?id=5',
        'VSL-S00000008': ''
      },
      model: ''
    };
  },
  methods: {
    setSpecies() {
      getSpeciesDetail(this.$route.params.slug).then(res => {
        this.species = res.data.data;
        this.pics = res.data.data.pics;
        this.model = this.models[this.species.slug]
      });
    },
    getAddrName(addr) {
      let province = '';
      let city = '';
      let district = '';
      let township = '';
      addr.map(element => {
        if (element['level'] === 'province') {
          if (element['name']) {
            province = element['name'];
          }
        } else if (element['level'] === 'city') {
          if (element['name']) {
            city = element['name']
          }
        } else if (element['level'] === 'district') {
            if (element['name']) {
              district = element['name']
            }
        } else if (element['level'] === 'township') {
            if (element['name']) {
              township = element['name']
            }
        }
      })
      if (province === city) {
        return province + district + township;
      } else {
        return province + city + district + township;
      }
    },
    onPicClick(pic) {
      let pics = [pic.lossless];
      for (const p of this.pics) {
        if (p.lossless !== pic.lossless) {
          pics.push(p.lossless);
        }
      }
      this.$viewerApi({
        images: pics
      });
    },
    onSeqClick(slug) {
      this.loading = true;
      getSeqDetail(slug).then(res => {
        this.seqSlug = res.data.data.slug;
        this.fasta = res.data.data.fasta;
        this.loading = false;
        this.seqVisible = true;
      });
    },
    saveSeq() {
      const blob = new Blob(
          [this.fasta],
          {type: 'text/plain;charset=utf-8'}
      );
      saveAs(blob, this.seqSlug + '.fa');
    },
    getAdcodes(districts) {
      const adcodes = new Set();
      for (const district of districts) {
        for (const element of district) {
          if (element['level'] === 'province') {
            adcodes.add(element['adcode']);
          }
        }
      }
      return Array.from(adcodes);
    },
    showDistributionMap() {
      this.mapVisible = true;
      this.mapTitle = '分布地地图';
      this.$nextTick(() => {
        this.map = new window.AMap.Map('map', {
          zoom: 4,
          center: [108, 34],
        });
        const marker = new window.AMap.Marker({
          content: 'foo',
          offset: new window.AMap.Pixel(0, 0),
          bubble: true
        });
        this.map.on('mousemove', function (e) {
          marker.position = e.pos;
          marker.setMap(this.map);
        });
        this.map.on('mouseout', function () {
          marker.setMap(null);
        });
        const adcodes = this.getAdcodes(this.species.distribution);
        const provinces = new window.AMap.DistrictLayer.Province({
          zIndex: 12,
          adcode: adcodes,
          depth: 0,
          styles: {
            'fill': '#CCCCCC',
            'province-stroke': 'cornflowerblue',
            'city-stroke': 'white', // 中国地级市边界
            'county-stroke': 'rgba(255,255,255,0.5)' // 中国区县边界
          }
        });
        provinces.setMap(this.map)
      });
    },
    showCollectionMap() {
      this.mapVisible = true;
      this.mapTitle = '采集地地图';
      this.$nextTick(() => {
        this.map = new window.AMap.Map('map', {
          zoom: 4,
          center: [108, 34],
        });
        const adcodes = this.getAdcodes(this.species.collection);
        const provinces = new window.AMap.DistrictLayer.Province({
          zIndex: 12,
          adcode: adcodes,
          depth: 0,
          styles: {
            'fill': '#CCCCCC',
            'province-stroke': 'cornflowerblue',
            'city-stroke': 'white', // 中国地级市边界
            'county-stroke': 'rgba(255,255,255,0.5)' // 中国区县边界
          }
        });
        provinces.setMap(this.map)
      });
    }
  },
  mounted() {
    this.setSpecies();
  }
}
</script>

<style lang="stylus" scoped>
#main-content
  padding-top: 2rem

  .thumbnail-pic
    width: 400px
    height: 400px
    margin: 0 auto

    img
      width: 100%

.lossless-pic
  text-align: center

  img
    max-width: 100%

.copy-to-clipboard
  cursor: pointer

#map
  height: 600px
</style>
